import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const menuCardStyles = makeStyles((theme: Theme) => ({
   card: {
      minWidth: theme.spacing(25),
      maxHeight: theme.spacing(17.375),
      minHeight: theme.spacing(16.125),
   },
   cardActionArea: {
      display: 'flex',
      alignItems: 'end',
      flexDirection: 'column',
      justifyContent: 'start',
      padding: theme.spacing(2),
      paddingRight: theme.spacing(0),
   },
   cardImage: {
      width: theme.spacing(12.5),
   },
   muiIcon: {
      fontSize: theme.spacing(4.5),
   },
}));

export default menuCardStyles;

import { gql } from '@apollo/client';

export const UserFragment = gql`
   fragment UserFragment on User {
      _id
      phoneNumber
      firstName
      surname
      email
      updatedAt
      createdAt
      roles
      isBlocked
      groupId
   }
`;

export const ListInfoFragment = gql`
   fragment ListInfoFragment on ListResultInfo {
      step
      limit
      term
      total
   }
`;

export const VehicleFragment = gql`
   ${UserFragment}
   fragment VehicleFragment on Vehicle {
      _id
      plate
      status
      createdAt
      details {
         color
         brand
         model
         registrationDate
      }
      owner {
         ...UserFragment
      }
   }
`;

export const UserGroupFragment = gql`
   ${UserFragment}
   fragment UserGroupFragment on UserGroup {
      _id
      name
      users {
         ...UserFragment
      }
      createdAt
      updatedAt
   }
`;

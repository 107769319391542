import { useQuery } from '@apollo/client';
import { LANG } from 'core/general-setup';

import { ME } from 'graphql/queries/me';
import { useAppDispatch } from 'store';
import { addUser } from 'store/reducers/user.reducer';
import { AvailableLanguages } from 'utils/enums';
import { ArrayString } from 'utils/interfaces';
import { Nullable } from 'utils/types';

export default function useIitMe(skip = false) {
   const dispatch = useAppDispatch();

   const { refetch: getMe } = useQuery(ME, {
      skip,
      onCompleted: (me: Nullable<ArrayString>) => {
         if (me) {
            let userInfo = { ...me?.me, roles: me?.me.roles };
            userInfo = {
               ...userInfo,
               fullName: `${userInfo?.firstName} ${userInfo?.surname}`,
            };
            dispatch(addUser(userInfo));
            localStorage.setItem(LANG, AvailableLanguages.PT);
         }
      },
   });

   return {
      getMe,
   };
}

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const navbarStyles = makeStyles((theme: Theme) => ({
   companyLogoStyle: {
      maxWidth: theme.spacing(28.125),
      height: theme.spacing(5),
      borderRadius: theme.spacing(0.625),
      cursor: 'pointer',
   },
   cursorPointer: {
      cursor: 'pointer',
   },
}));

export default navbarStyles;

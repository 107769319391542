enum MOBILE_NAVIGATION_COMPONENT_PATHS {
   DASHBOARD = '/dashboard',
   OPERATIONS = '/operations',
   PROFILE = '/profile',
}

export const findFirstViewOnInit = (pathname: string) => {
   switch (pathname) {
      case MOBILE_NAVIGATION_COMPONENT_PATHS.DASHBOARD:
         return 0;
      case MOBILE_NAVIGATION_COMPONENT_PATHS.OPERATIONS:
         return 1;
      case MOBILE_NAVIGATION_COMPONENT_PATHS.PROFILE:
         return 2;
      default:
         return 1;
   }
};

export const navigateMobileNavigation = (value: number): string => {
   switch (value) {
      case 0:
         return MOBILE_NAVIGATION_COMPONENT_PATHS.DASHBOARD;
      case 1:
         return MOBILE_NAVIGATION_COMPONENT_PATHS.OPERATIONS;
      case 2:
         return MOBILE_NAVIGATION_COMPONENT_PATHS.PROFILE;
      default:
         return MOBILE_NAVIGATION_COMPONENT_PATHS.DASHBOARD;
   }
};

import { useNavigate, useLocation } from 'react-router-dom';

/**
 *
 * @summary
 * UrlNumber type of navigate to back
 */
type UrlNumber = -1;

export default function useIitRouting() {
   const navigate = useNavigate();
   const { pathname } = useLocation();

   const BASE_PATH = `${pathname.split('/')[1]}`;

   const navigateTo = (url: string | UrlNumber, reset = false) => {
      const typeOfUrl = typeof url;

      if (typeOfUrl === 'number') return navigate(-1);

      return reset ? navigate(url as string) : navigate(`/${BASE_PATH}${url}`);
   };

   return {
      navigateTo,
      BASE_PATH,
   };
}

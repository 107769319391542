import { DEFAULT_LANG } from 'core/general-setup';
import { AvailableLanguages, LANGUAGE_TRANSLATION } from 'utils/enums';

export interface ILanguage {
   name: string;
   value: AvailableLanguages;
   current?: boolean;
}

export const defaultLang = (storedLang: string | null): ILanguage => {
   const obj = {
      value: storedLang as AvailableLanguages,
   };

   switch (storedLang) {
      case AvailableLanguages.PT:
         return { ...obj, name: LANGUAGE_TRANSLATION.PT };
      case AvailableLanguages.EN:
         return { ...obj, name: LANGUAGE_TRANSLATION.EN };
      default:
         return DEFAULT_LANG;
   }
};

import React, { useEffect, useState } from 'react';

import { Box, CardActionArea } from '@mui/material';

import { IModel } from 'components/home/types';
import { OPACITY_DISABLED } from 'components/home/components/menu-card/menu-card';
import { setupTheme } from 'core/general-setup';
import useIitRouting from 'hooks/iit-use-routing';
import { themeColors } from 'style/common';
import { importImage } from 'utils/utils';

import modelCardStyles from './style';

interface IMenuCard {
   model: IModel;
}

export default function ModelCard({
   model: { basePath, redirectPath, srcImage, disabled },
}: IMenuCard) {
   const [image, setImage] = useState();

   const { root } = modelCardStyles();
   const theme = setupTheme();

   const { navigateTo } = useIitRouting();
   const { BASE_PATH } = useIitRouting();

   const SX_CARD_ACTION = {
      background: BASE_PATH === basePath ? themeColors.grayEvenLighter : undefined,
      opacity: disabled ? OPACITY_DISABLED : 1,
   };

   useEffect(() => {
      importImage(srcImage).then((res) => setImage(res.default));
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <CardActionArea
         className={root}
         sx={SX_CARD_ACTION}
         onClick={() => navigateTo(`/${basePath}/${redirectPath}`, true)}
         disabled={disabled}
      >
         <Box width={theme.spacing(5.625)} component="img" src={image} marginBottom={1} />
      </CardActionArea>
   );
}

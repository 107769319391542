import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { themeColors } from 'style/common';

const mobileNavigationStyles = makeStyles((theme: Theme) => ({
   root: {
      borderTop: `1px solid ${themeColors.divider}`,
      position: 'fixed',
      bottom: 0,
      width: '100%',
      zIndex: 1,
   },
}));

export default mobileNavigationStyles;

import React, { useState } from 'react';

import { Snackbar } from '@mui/material';
import Alert, { AlertColor } from '@mui/material/Alert';

import { useIitGetObservableWithTopic } from 'hooks/iit-use-get-observable-with-topic';
import { ALERT } from 'utils/pubsub-topics';
import { Nullable } from 'utils/types';
import { setupTheme } from 'core/general-setup';

interface IAlertContent {
   open: boolean;
   severity?: AlertColor;
   message: string;
}

function AlertModal() {
   const [alertContent, setAlertContent] = useState<IAlertContent>({
      open: false,
      message: '',
   });

   const theme = setupTheme();

   useIitGetObservableWithTopic(ALERT, {
      onCompleted: (data: Nullable<IAlertContent>) => {
         if (data) setAlertContent(data);
      },
   });

   return (
      <Snackbar
         anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         open={alertContent?.open}
         autoHideDuration={2000}
         onClose={() => setAlertContent({ ...alertContent, open: false })}
      >
         <Alert
            elevation={6}
            variant="filled"
            severity={alertContent?.severity || 'info'}
            onClose={() => setAlertContent({ ...alertContent, open: false })}
            sx={{ borderRadius: theme.spacing(0.5) }}
         >
            {alertContent?.message || ''}
         </Alert>
      </Snackbar>
   );
}

export default AlertModal;

import { useTranslation } from 'react-i18next';

import { Box, Button, Grid, Typography } from '@mui/material';

import NotFoundSvg from './svg';
import useIitRouting from 'hooks/iit-use-routing';
import { NAVIGATE_TO_HOME } from 'utils/constants';

export default function NotFound() {
   const { t } = useTranslation();

   const { navigateTo } = useIitRouting();

   return (
      <Grid
         display="flex"
         alignItems="center"
         justifyContent="center"
         height="100vh"
         container
         spacing={2}
         padding={4}
      >
         <Grid item xs={12} md={4}>
            <Box marginBottom={4}>
               <Typography sx={{ typography: { xs: 'h4', xl: 'h2' } }}>
                  {t('COMPONENT_404.WHOOPS_OUTER_SPACE')}
               </Typography>
               <Typography variant="body1" marginTop={2}>
                  {t('COMPONENT_404.THE_PAGE_YOU_ARE_LOOKING_FOR_DOES_NOT_EXIST')}
               </Typography>
            </Box>
            <Button
               variant="contained"
               size="large"
               onClick={() => navigateTo(NAVIGATE_TO_HOME, true)}
            >
               {t('COMPONENT_404.GO_TO_HOMEPAGE')}
            </Button>
         </Grid>
         <Grid item xs={12} md={6}>
            <NotFoundSvg />
         </Grid>
      </Grid>
   );
}

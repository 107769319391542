import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { themeColors } from 'style/common';

const listItemStyles = makeStyles((theme: Theme) => ({
   item: {
      borderRadius: 5,
      '&.Mui-selected': {
         backgroundColor: themeColors.primaryEvenLighter,
         color: themeColors.primary,
      },
      minHeight: 45,
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
   },
   closedDrawerItemButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: 50,
      width: 50,
   },
}));

export default listItemStyles;

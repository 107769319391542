import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const modelCardStyles = makeStyles((theme: Theme) => ({
   root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      padding: theme.spacing(1),
      borderRadius: theme.spacing(1),
   },
}));

export default modelCardStyles;

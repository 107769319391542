import dayjs from 'dayjs';
import { LIST_INFO_KEYS, OrdersStatus, PORTAL_ROLES, VIEW_MODES } from './enums';
import { IListInfo } from './interfaces';
import { NAVIGATION_PATHS } from './routes';

export const REQUIRED_FIELD = 'GENERAL.REQUIRED_FIELD';

export const LOADING_PUB_SUB_TOPIC = 'LOADING_PUBSUB_TOPIC';
export const UNLOADING_PUB_SUB_TOPIC = 'UNLOADING_PUBSUB_TOPIC';

export const REFRESH_CURRENT_POSITION = 'RefreshCurrentPosition';

export const CURRENT_DATE = dayjs().toISOString();

export const TODAY_DATE_LESS_ONE_MONTH = dayjs().subtract(1, 'month').toISOString();
export const TODAY_DATE_LESS_THREE_MONTH = dayjs().subtract(3, 'month').toISOString();

export const NAVIGATE_TO_HOME = `/${NAVIGATION_PATHS.HOME}`;
export const DEFAULT_NAVIGATE_WEB_VIEW_MODE = `/vehicles/my-passages?viewMode=${VIEW_MODES.WEB_VIEW_MODE}`;

export const STATUS = [
   OrdersStatus.ALL,
   OrdersStatus.CANCELED,
   OrdersStatus.PARTIAL_SHIPPED,
   OrdersStatus.PROCESSING,
   OrdersStatus.READY_TO_SHIP,
   OrdersStatus.REGISTERED,
   OrdersStatus.SHIPPED,
   OrdersStatus.WAITING_PAYMENT,
];

export const STATIC_IDENTIFIERS = {
   OPEN_DOCUMENT: {
      NAME: 'Open Document',
      ID: 'DOCS_OPEN_DOCUMENT',
   },
   CHANGE_ROUT: {
      NAME: 'Change Route',
      ID: 'WEB_CHANGE_ROUTE',
   },
};

export const ROLES_ACCESS = {
   ALL: [PORTAL_ROLES.ADMIN, PORTAL_ROLES.CLIENT, PORTAL_ROLES.CLIENT_CARE],
   ADMIN: [PORTAL_ROLES.ADMIN],
   ADMIN_CLIENT: [PORTAL_ROLES.ADMIN, PORTAL_ROLES.CLIENT],
   ADMIN_CLIENT_CARE: [PORTAL_ROLES.ADMIN, PORTAL_ROLES.CLIENT_CARE],
   CLIENT: [PORTAL_ROLES.CLIENT],
   CLIENT_CLIENT_CARE: [PORTAL_ROLES.CLIENT, PORTAL_ROLES.CLIENT_CARE],
   CLIENT_CARE: [PORTAL_ROLES.CLIENT_CARE],
};

export const REGEXP = {
   HAS_NUMBER: /\d/,
   HAS_UPPERCASE: /[A-Z]/,
   HAS_SPECIAL_CHAR: /[^A-Za-z0-9]/,
};

export enum RN_WEBVIEW_EVENTS {
   CHANGE_LANGUAGE = 'change_language',
   LOGOUT = 'logout',
   CHANGE_SETTINGS = 'change_settings',
}

export const DEFAULT_LIST_INFO_KEYS = {
   step: LIST_INFO_KEYS.STEP,
   limit: LIST_INFO_KEYS.LIMIT,
   total: LIST_INFO_KEYS.TOTAL,
};

export const DEFAULT_LIST_KEYS = {
   listInfo: DEFAULT_LIST_INFO_KEYS,
   list: 'data',
};

export const DEFAULT_PAGE_INFO: IListInfo = {
   step: 0,
   limit: 10,
   total: 0,
};

export const LIST_LIMIT = 10;

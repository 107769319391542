import { IModel } from 'components/home/types';
import { PortalPage } from 'utils/interfaces';

export const STRUCTURE: PortalPage[] = [
   // Dashboard
   // {
   //    id: 'Pag_Dashboard',
   //    name: 'Dashboard',
   //    path: '/dashboard',
   //    layout: 'layout_100',
   //    roles: ['ADMIN', 'HOTEL_MANAGER'],
   //    title: 'COMPONENT_DASHBOARD.DASHBOARD',
   //    components: [
   //       {
   //          position: 0,
   //          location: 'components/dashboard/components/last-passages',
   //          width: 50,
   //       },
   //       {
   //          position: 1,
   //          width: 25,
   //          location: 'components/dashboard/components/average',
   //       },
   //       {
   //          position: 2,
   //          width: 25,
   //          location: 'components/dashboard/components/invite-user',
   //          routing: {
   //             USERS_DETAIL: 'user-detail',
   //          },
   //       },
   //    ],
   // },
   // Pag Profile
   {
      id: 'Pag_Profile',
      name: 'Profile',
      path: '/profile',
      layout: 'layout_100',
      roles: ['ADMIN', 'HOTEL_MANAGER', 'TEMPORARY_USER', 'RESIDENT_USER'],
      title: 'GENERAL.PROFILE',
      components: [
         {
            position: 0,
            routing: {
               PRODUCTS_DETAIL: '/productsDetail',
            },
            location: 'components/profile',
         },
      ],
   },
   // Pag Vehicles
   {
      id: 'Pag_Vehicles',
      name: 'Vehicles',
      path: '/pending',
      layout: 'layout_100',
      roles: ['ADMIN', 'HOTEL_MANAGER', 'TEMPORARY_USER', 'RESIDENT_USER'],
      title: 'VEHICLES_COMPONENT.PENDING_VEHICLES',
      components: [
         {
            position: 0,
            location: 'components/vehicles',
         },
      ],
   },
   {
      id: 'Pag_Vehicles',
      name: 'Vehicles',
      path: '/my-vehicles',
      layout: 'layout_100',
      roles: ['ADMIN', 'HOTEL_MANAGER', 'TEMPORARY_USER', 'RESIDENT_USER'],
      title: 'VEHICLES_COMPONENT.PENDING_VEHICLES',
      components: [
         {
            position: 0,
            location: 'components/vehicles',
         },
      ],
   },
   // Pag Passages
   {
      id: 'Pag_Passages',
      name: 'Passages',
      path: '/passages',
      layout: 'layout_100',
      roles: ['ADMIN', 'TEMPORARY_USER', 'RESIDENT_USER'],
      title: 'PASSAGES_COMPONENT.PASSAGES',
      components: [
         {
            position: 0,
            location: 'components/passages',
         },
      ],
   },
   {
      id: 'Pag_Passages',
      name: 'Passages',
      path: '/my-passages',
      layout: 'layout_100',
      roles: ['ADMIN', 'TEMPORARY_USER', 'RESIDENT_USER'],
      title: 'PASSAGES_COMPONENT.PASSAGES',
      components: [
         {
            position: 0,
            location: 'components/passages',
            routing: {
               PASSAGES_DETAILS: 'passages-details',
            },
         },
      ],
   },
   {
      id: 'Pag_PassagesCreate',
      name: 'Passages',
      path: '/passages-details',
      layout: 'layout_100',
      roles: ['ADMIN', 'TEMPORARY_USER', 'RESIDENT_USER'],
      title: 'PASSAGES_COMPONENT.PASSAGES',
      components: [
         {
            position: 0,
            location: 'components/passages/passages-details',
         },
      ],
   },
   // Pag Users
   {
      id: 'Pag_User',
      name: 'Users',
      path: '/users-list',
      layout: 'layout_100',
      roles: ['ADMIN'],
      title: 'GENERAL.USERS',
      components: [
         {
            position: 0,
            location: 'components/users',
            routing: {
               USERS_DETAIL: 'user-detail',
            },
         },
      ],
   },
   {
      id: 'Pag_UserActions',
      name: 'Users Action',
      path: '/user-detail',
      layout: 'layout_100',
      roles: ['ADMIN', 'HOTEL_MANAGER'],
      title: 'COMPONENT_CLIENT_DETAIL.USER_DETAIL',
      components: [
         {
            position: 0,
            location: 'components/users/components/user-detail',
            routing: {
               USERS_LIST: 'users-list',
            },
         },
         {
            position: 1,
            location: 'components/vehicles/',
            routing: {
               USERS_LIST: 'users-list',
            },
         },
      ],
   },
   {
      id: 'Pag_HotelsList',
      name: 'Hotels List',
      path: '/hotels',
      layout: 'layout_100',
      roles: ['ADMIN', 'HOTEL_MANAGER'],
      title: 'SIDE_BAR.HOTELS',
      components: [
         {
            position: 0,
            location: 'components/hotels',
            routing: {
               USERS_LIST: 'users-list',
               HOTEL_DETAILS: 'hotel-details',
               USERS_DETAIL: 'user-detail',
            },
         },
      ],
   },
   {
      id: 'Pag_HotelDetails',
      name: 'Hotels Details',
      path: '/hotel-details',
      layout: 'layout_100',
      roles: ['ADMIN', 'HOTEL_MANAGER'],
      title: 'GENERAL.HOTEL_DETAIL',
      components: [
         {
            position: 0,
            location: 'components/hotels/components/hotel-details',
            routing: {
               HOTEL_DETAILS: 'hotel-details',
               USERS_LIST: 'users-list',
               USERS_DETAIL: 'user-detail',
            },
         },
      ],
   },
];

export const IMPORT_CLIENTS_CONFIG = {
   idKey: 'id',
   nameKey: 'name',
};

export const PORTAL_MODULES: IModel[] = [
   {
      id: 'Module_Users',
      srcImage: 'assets/home/users.png',
      label: 'COMPONENT_HOME.USERS',
      basePath: 'users',
      redirectPath: 'hotels',
      backgroundColor: '#FFD966',
      disabled: false,
      menuOptions: [
         // {
         //    name: 'SIDE_BAR.DASHBOARD',
         //    url: '/dashboard',
         //    roles: ['ADMIN', 'HOTEL_MANAGER'],
         //    icon: 'dashboard',
         // },
         {
            name: 'SIDE_BAR.HOTELS',
            url: '/hotels',
            roles: ['ADMIN', 'HOTEL_MANAGER'],
            icon: 'hotel',
         },
         {
            name: 'SIDE_BAR.USERS',
            url: '/users-list',
            roles: ['ADMIN'],
            icon: 'group_add',
         },
      ],
      roles: ['ADMIN', 'HOTEL_MANAGER'],
   },
   {
      id: 'Module_Vehicles',
      srcImage: 'assets/home/vehicles.png',
      label: 'COMPONENT_HOME.VEHICLES',
      basePath: 'vehicles',
      redirectPath: 'pending',
      backgroundColor: '#76b5c5',
      disabled: false,
      menuOptions: [
         {
            name: 'SIDE_BAR.PENDING_VEHICLES',
            url: '/pending',
            roles: ['ADMIN', 'HOTEL_MANAGER', 'TEMPORARY_USER', 'RESIDENT_USER'],
            icon: 'time_to_leave',
         },
         {
            name: 'SIDE_BAR.PASSAGES',
            url: '/passages',
            roles: ['ADMIN', 'HOTEL_MANAGER', 'TEMPORARY_USER', 'RESIDENT_USER'],
            icon: 'badge',
         },
      ],
      roles: ['ADMIN', 'TEMPORARY_USER', 'RESIDENT_USER'],
   },
];

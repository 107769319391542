const ENV = {
   REACT_APP_BRAND: process.env.REACT_APP_BRAND || 'InflightIT',
   REACT_APP_BRAND_WEBSITE:
      process.env.REACT_APP_BRAND_WEBSITE || '"https://inflightit.com/"',
   REACT_APP_REST_ENDPOINT:
      process.env.REACT_APP_REST_ENDPOINT || 'http://localhost:3004',
   REACT_APP_EMAIL_REGEX:
      process.env.REACT_APP_EMAIL_REGEX || process.env.NODE_ENV === 'development'
         ? new RegExp(/^\w+([+.-]?\w+)*@\w+([.-]?\w+)(\.\w{2,3})+$/i)
         : new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)(\.\w{2,3})+$/i),
   REACT_APP_DATE_FORMAT: process.env.REACT_APP_DATE_FORMAT || 'DD-MM-YYYY hh:mm',
   REACT_APP_TOKEN_KEY: process.env.REACT_APP_TOKEN_KEY || 'token',
   REACT_APP_AUTH_ENDPOINT:
      process.env.REACT_APP_AUTH_ENDPOINT || 'http://localhost:3001',
   REACT_APP_GRAPHQL_ENDPOINT:
      process.env.REACT_APP_GRAPHQL_ENDPOINT || 'http://localhost:3001/graphql',
   REACT_APP_SHORT_POLLING_DELAY: process.env.REACT_APP_SHORT_POLLING_DELAY || 30000,
   REACT_APP_SCHEDULER_LOCALE: process.env.SCHEDULER_LOCALE || 'pt-PT',
   REACT_APP_MEASURABLES_ENDPOINT:
      process.env.REACT_APP_MEASURABLES_ENDPOINT ||
      'http://localhost:3004/measurable/log-mesurables',
   MEASURABLES_CONFIG: {
      NAME: 'Authorezation',
      KEY: '99HY00TDGS8635PPTPIRWDMB1',
   },
   REACT_APP_TEMPORARY_USERS_EXPIRES_IN:
      process.env.REACT_APP_TEMPORARY_USERS_EXPIRES_IN || 5,
};

export const PATHS = {
   LOGIN: '/auth/login',
   UPLOAD: '/upload',
};

export default ENV;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Box, Card, CardActionArea, Typography } from '@mui/material';

import { IModel } from 'components/home/types';
import { setupTheme } from 'core/general-setup';
import useIitRouting from 'hooks/iit-use-routing';
import { importImage } from 'utils/utils';

import menuCardStyles from './style';

interface IMenuCard {
   model: IModel;
}

export const OPACITY_DISABLED = 0.5;

export default function MenuCard({
   model: { basePath, redirectPath, srcImage, label, backgroundColor, disabled },
}: IMenuCard) {
   const [image, setImage] = useState();

   const { card, cardActionArea, cardImage } = menuCardStyles();

   const { t } = useTranslation();
   const { navigateTo } = useIitRouting();
   const theme = setupTheme();

   const BACKGROUND_IMAGE = useCallback(
      (lightNumber: number) => ({
         backgroundImage: `linear-gradient(to bottom right, ${backgroundColor}${lightNumber}, ${backgroundColor}${lightNumber})`,
      }),
      [backgroundColor],
   );

   const CARD_SX = useMemo(() => {
      const LIGHT_NUMBER = disabled ? 25 : 60;
      const OPACITY = disabled ? OPACITY_DISABLED : 1;

      return {
         ...BACKGROUND_IMAGE(LIGHT_NUMBER),
         opacity: OPACITY,
      };
   }, [BACKGROUND_IMAGE, disabled]);

   useEffect(() => {
      importImage(srcImage).then((res: any) => setImage(res?.default));
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <Card className={card} sx={CARD_SX}>
         <CardActionArea
            className={clsx(card, cardActionArea)}
            onClick={() => navigateTo(`/${basePath}/${redirectPath}`, true)}
            disabled={disabled}
         >
            <Box
               display="flex"
               marginBottom={2}
               paddingRight={2}
               width={`calc(100% - ${theme.spacing(2)})`}
            >
               <Typography variant="body1" whiteSpace="nowrap">
                  {t(label)}
               </Typography>
            </Box>

            <Box className={cardImage} component="img" src={image} />
         </CardActionArea>
      </Card>
   );
}

export const CATALOG_FILTERS = 'CatalogFilters';
export const HEADER_CART = 'HeaderCart';
export const AGENT_SEARCH = 'AgentSearch';
export const ORDERS_FILTERS = 'OrdersFilters';
export const CAMPAIGNS_FILTERS = 'CampaignsFilters';
export const CAMPAIGNS_LIST_FILTERS = 'CampaignsListFilters';
export const USER_PROFILE = 'UserProfile';
export const ALERT = 'Alert';
export const SHARED_HEADER_FILTER = 'SharedHeaderFilter';
export const NOTIFICATIONS_FILTERS = 'NotificationsFilter';
export const SIDE_FILTERS = 'SideFilters';
export const CHECKING_ACCOUNT_FILTERS = 'CheckingAccountFilters';
export const REQUEST_INFORMATION_FILTERS = 'RequestInfoFilters';
export const CLIENTS_FILTERS = 'ClientsFilters';
export const INVITE_USER = 'InviteUser';

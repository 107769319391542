import React, { useState } from 'react';

import { useIitGetObservableWithTopic } from 'hooks/iit-use-get-observable-with-topic';

import { IitCircularLoading } from './iit-loadings/iit-circular-loading/iit-circular-loading';

import { LOADING_PUB_SUB_TOPIC, UNLOADING_PUB_SUB_TOPIC } from '../../utils/constants';

const IitPubLoading = () => {
   const [count, setCount] = useState(0);

   useIitGetObservableWithTopic(LOADING_PUB_SUB_TOPIC, {
      onCompleted: () => setCount((pev) => pev + 1),
   });

   useIitGetObservableWithTopic(UNLOADING_PUB_SUB_TOPIC, {
      onCompleted: () => setCount((pev) => pev - 1),
   });

   if (count === 0) return <></>;

   return <IitCircularLoading />;
};

export { IitPubLoading };

import { Box, Divider, Typography } from '@mui/material';
import { IitComponentDefaultLayout } from './shared-components/iit-component-layout-default';
import { mobileStyle } from 'style/common';
import Header from './header';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { GetQueryParameter } from 'containers/shell';

interface IData {
   title: string;
   data: {
      title: string;
      body: string;
      enumeration?: { label: string; text: string }[];
   }[];
   lastText: string;
}

const content: IData[] = [
   {
      title: 'Política de Privacidade do Gestor de Tráfego de Regaleira/Sintra',
      data: [
         {
            title: 'Introdução',
            body: 'Bem-vindo ao Gestor de Tráfego de Regaleira/Sintra. Valorizamos a sua privacidade e estamos comprometidos em proteger as suas informações pessoais. Esta Política de Privacidade explica como recolhemos, usamos e divulgamos informações sobre você quando utiliza a nossa aplicação móvel.',
         },
         {
            title: 'Informações que Recolhemos',
            body: 'Recolhemos os seguintes tipos de informações:',
            enumeration: [
               {
                  label: 'Informações de Registro:',
                  text: 'Quando se regista na nossa aplicação, recolhemos o seu nome, endereço de email, número de telefone e detalhes do veículo, incluindo números de matrícula.',
               },
               {
                  label: 'Dados de Uso:',
                  text: 'Recolhemos dados sobre as suas interações com a nossa aplicação, incluindo passagens por semáforos, horários de passagem e quaisquer erros encontrados.',
               },
               {
                  label: 'Imagens de Vídeo:',
                  text: 'As nossas câmaras capturam imagens de vídeo dos veículos que passam por Regaleira. Estas imagens são usadas para ler e verificar matrículas.',
               },
            ],
         },
         {
            title: 'Como Usamos as Suas Informações',
            body: 'Usamos as informações que recolhemos para os seguintes propósitos:',
            enumeration: [
               {
                  label: 'Para Fornecer e Melhorar os Nossos Serviços:',
                  text: 'Utilizamos as suas informações de registro e do veículo para facilitar a sua passagem pelos semáforos de Regaleira.',
               },
               {
                  label: 'Para Garantir a Segurança:',
                  text: 'As imagens de vídeo ajudam-nos a verificar o registro dos veículos e a garantir a segurança do nosso sistema de gestão de tráfego.',
               },
               {
                  label: 'Para Comunicar com Você:',
                  text: 'Podemos utilizar as suas informações de contato para lhe enviar atualizações, notificações e outras informações importantes sobre os nossos serviços.',
               },
            ],
         },
         {
            title: 'Como Partilhamos as Suas Informações',
            body: 'Não partilhamos as suas informações pessoais com terceiros, exceto nas seguintes circunstâncias:',
            enumeration: [
               {
                  label: 'Com o Seu Consentimento:',
                  text: 'Podemos partilhar as suas informações com terceiros se nos tiver dado consentimento explícito para fazê-lo.',
               },
               {
                  label: 'Por Razões Legais:',
                  text: 'Podemos divulgar as suas informações se for exigido por lei ou em resposta a pedidos válidos por autoridades públicas.',
               },
               {
                  label: 'Com Prestadores de Serviços:',
                  text: 'Podemos partilhar as suas informações com prestadores de serviços terceiros que realizam serviços em nosso nome, como armazenamento de dados e serviços de segurança.',
               },
            ],
         },
         {
            title: 'Segurança dos Dados',
            body: 'Implementamos medidas técnicas e organizacionais apropriadas para proteger as suas informações pessoais contra acesso não autorizado, perda ou destruição. No entanto, nenhuma medida de segurança é perfeita e não podemos garantir segurança absoluta.',
         },
         {
            title: 'Acesso e Controle Sobre as Suas Informações',
            body: 'Você tem o direito de aceder, atualizar e eliminar as suas informações pessoais. Para apagar a sua conta, pode enviar um email para regaleira@inflightit.com com o assunto "Apagar conta".',
         },
         {
            title: 'Retenção de Informações',
            body: 'Mantemos as suas informações pessoais pelo tempo necessário para fornecer os nossos serviços e conforme exigido por lei. As imagens de vídeo são mantidas por um período limitado e depois eliminadas de forma segura, a menos que sejam necessárias para fins legais ou de segurança.',
         },
         {
            title: 'Alterações a Esta Política de Privacidade',
            body: 'Podemos atualizar esta Política de Privacidade periodicamente. Notificá-lo-emos de quaisquer alterações publicando a nova Política de Privacidade na nossa aplicação e atualizando a data de vigência no topo deste documento.',
         },
         {
            title: 'Contacte-nos',
            body: 'Se tiver quaisquer perguntas ou preocupações sobre esta Política de Privacidade ou as nossas práticas de dados, por favor contacte-nos em:',
            enumeration: [
               {
                  label: 'Empresa:',
                  text: 'Inflight IT, Lda.',
               },
               {
                  label: 'Email:',
                  text: 'regaleira@inflightit.com',
               },
               {
                  label: 'Endereço:',
                  text: 'Rua da Regaleira, Sintra, Portugal',
               },
            ],
         },
      ],
      lastText:
         'Obrigado por usar o Gestor de Tráfego de Regaleira/Sintra. A sua privacidade e segurança são importantes para nós.',
   },
   {
      title: 'Privacy Policy for Regaleira/Sintra Traffic Manager',
      data: [
         {
            title: 'Introduction',
            body: 'Welcome to Regaleira/Sintra Traffic Manager. We value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and disclose information about you when you use our mobile application.',
         },
         {
            title: 'Information We Collect',
            body: 'We collect the following types of information:',
            enumeration: [
               {
                  label: 'Registration Information:',
                  text: 'When you register on our app, we collect your name, email address, phone number, and vehicle details, including license plate numbers.',
               },
               {
                  label: 'Usage Data:',
                  text: 'We collect data on your interactions with our app, including passages through traffic lights, times of passage, and any errors encountered.',
               },
               {
                  label: 'Video Footage:',
                  text: 'Our cameras capture video footage of vehicles passing through Regaleira. This footage is used to read and verify license plates.',
               },
            ],
         },
         {
            title: 'How We Use Your Information',
            body: 'We use the information we collect for the following purposes:',
            enumeration: [
               {
                  label: 'To Provide and Improve Our Services:',
                  text: "We use your registration and vehicle information to facilitate your smooth passage through Regaleira's traffic lights.",
               },
               {
                  label: 'To Ensure Security:',
                  text: 'Video footage helps us verify vehicle registration and ensure the security of our traffic management system.',
               },
               {
                  label: 'To Communicate with You:',
                  text: 'We may use your contact information to send you updates, notifications, and other important information about our services.',
               },
            ],
         },
         {
            title: 'How We Share Your Information',
            body: 'We do not share your personal information with third parties, except in the following circumstances:',
            enumeration: [
               {
                  label: 'With Your Consent:',
                  text: 'We may share your information with third parties if you have given us explicit consent to do so.',
               },
               {
                  label: 'For Legal Reasons:',
                  text: 'We may disclose your information if required by law or in response to valid requests by public authorities.',
               },
               {
                  label: 'With Service Providers:',
                  text: 'We may share your information with third-party service providers who perform services on our behalf, such as data storage and security services.',
               },
            ],
         },
         {
            title: 'Data Security',
            body: 'We implement appropriate technical and organizational measures to protect your personal information against unauthorized access, loss, or destruction. However, no security measure is perfect, and we cannot guarantee absolute security.',
         },
         {
            title: 'Access and Control Over Your Information',
            body: 'You have the right to access, update, and delete your personal information. To delete your account, you can send an email to regaleira@inflightit.com with the subject "Delete Account".',
         },
         {
            title: 'Retention of Information',
            body: 'We retain your personal information for as long as necessary to provide our services and as required by law. Video footage is retained for a limited period and then securely deleted, unless needed for legal or security purposes.',
         },
         {
            title: 'Changes to This Privacy Policy',
            body: 'We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our app and updating the effective date at the top of this document.',
         },
         {
            title: 'Contact Us',
            body: 'If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:',
            enumeration: [
               {
                  label: 'Company:',
                  text: 'Inflight IT, Lda.',
               },
               {
                  label: 'Email:',
                  text: 'regaleira@inflightit.com',
               },
               {
                  label: 'Address:',
                  text: 'Rua da Regaleira, Sintra, Portugal',
               },
            ],
         },
      ],
      lastText:
         'Thank you for using Regaleira/Sintra Traffic Manager. Your privacy and security are important to us.',
   },
];

const PrivacyPolicy = () => {
   const mobile = mobileStyle();
   const { t } = useTranslation();

   const sectionId = GetQueryParameter().get('section');

   useEffect(() => {
      if (!sectionId) return;

      const section = document.getElementById(sectionId);

      if (section) {
         section.scrollIntoView({ behavior: 'smooth' });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <IitComponentDefaultLayout>
         <Header
            isWebViewMode
            pageTitle={t('GENERAL.PRIVACY_POLICY') as string}
            menuOptions={[]}
         />
         <Box
            display="flex"
            flexDirection="column"
            className={mobile.root}
            marginTop={8.875}
         >
            {content?.map((element, elIndex) => (
               <Box key={elIndex}>
                  <Typography variant="h6">{element.title}</Typography>
                  {element.data.map((item, index) => (
                     <Box
                        display="flex"
                        flexDirection="column"
                        key={index}
                        marginTop={2}
                        id={item.title.replaceAll(' ', '-').toLowerCase()}
                     >
                        <Typography variant="h6">
                           {index + 1}. {item.title}
                        </Typography>
                        <Typography variant="subtitle1" marginTop={2}>
                           {item.body}
                        </Typography>

                        {item.enumeration && item.enumeration.length > 0 && (
                           <Box display="flex" flexDirection="column">
                              {item.enumeration.map((element, enumIndex) => (
                                 <Box display="flex" marginTop={2} key={enumIndex}>
                                    <Typography variant="subtitle1">
                                       <span style={{ fontWeight: 'bold' }}>
                                          {String.fromCharCode(
                                             enumIndex + 1 + 'A'.charCodeAt(0) - 1,
                                          ).toLocaleLowerCase()}
                                          . {element.label}{' '}
                                       </span>
                                       {element.text}
                                    </Typography>
                                 </Box>
                              ))}
                           </Box>
                        )}
                     </Box>
                  ))}
                  <Typography variant="subtitle1" marginTop={2}>
                     {element.lastText}
                  </Typography>
                  {elIndex === 0 && <Divider sx={{ my: 4 }} />}
               </Box>
            ))}
         </Box>
      </IitComponentDefaultLayout>
   );
};

export default PrivacyPolicy;

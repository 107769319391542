import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const headerUserMenuStyles = makeStyles((theme: Theme) => ({
   divider: {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
   },
}));

export default headerUserMenuStyles;

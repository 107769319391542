import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { createTheme } from '@mui/material/styles';
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';

import ENV from 'config/environment';

import translationPT from '../translations/pt.json';
import translationEN from '../translations/en.json';
import { BOX_SHADOW, themeColors } from '../style/common';
import { AvailableLanguages, LANGUAGE_TRANSLATION } from '../utils/enums';

export const LANG = 'lang';
export const DEFAULT_LANG = {
   name: LANGUAGE_TRANSLATION.PT,
   value: AvailableLanguages.PT,
};

export const setupI18n = () => {
   i18n.use(initReactI18next).init({
      resources: {
         pt: {
            translation: translationPT,
         },
         en: {
            translation: translationEN,
         },
      },
      lng: localStorage.getItem(LANG) || DEFAULT_LANG.value,
      fallbackLng: localStorage.getItem(LANG) || DEFAULT_LANG.value,
      interpolation: {
         escapeValue: false,
      },
   });
};

export const setupTheme = () => {
   const theme = createTheme();
   return createTheme({
      palette: {
         primary: {
            main: themeColors.primary,
            contrastText: themeColors.generalContrastText,
            light: themeColors.primaryLight,
         },
         secondary: {
            main: themeColors.secondary,
            light: themeColors.secondaryLight,
         },
         divider: themeColors.divider,
         background: {
            default: themeColors.background,
         },
      },
      typography: {
         fontFamily: 'Lato',
         h1: {
            fontWeight: 'bold',
         },
         h2: {
            fontWeight: 'bold',
         },
         h3: {
            fontWeight: 'bold',
         },
         h4: {
            fontWeight: 'bold',
         },
         h5: {
            fontWeight: 'bold',
         },
         h6: {
            fontWeight: 'bold',
         },
      },
      components: {
         MuiButton: {
            styleOverrides: {
               root: {
                  textTransform: 'none',
                  boxShadow: 'none',
                  borderRadius: theme.spacing(0.5),
               },
            },
         },
         MuiPaper: {
            styleOverrides: {
               root: {
                  boxShadow: BOX_SHADOW,
                  backgroundColor: themeColors.backgroundSecondary,
               },
               rounded: {
                  borderRadius: theme.spacing(1.25),
               },
            },
         },
         MuiAccordion: {
            styleOverrides: {
               root: {
                  boxShadow: 'none',
                  background: 'none',

                  '&.Mui-expanded': {
                     marginTop: 0,
                     marginBottom: theme.spacing(1),
                  },

                  ':before': {
                     height: 0,
                  },
               },
            },
         },
         MuiAccordionSummary: {
            styleOverrides: {
               root: {
                  padding: 0,
                  margin: 0,
                  minHeight: 0,

                  '&.Mui-expanded': {
                     minHeight: 0,
                  },

                  '& .MuiAccordionSummary-content.Mui-expanded': {
                     marginTop: theme.spacing(1.5),
                     marginBottom: theme.spacing(1.5),
                  },
               },
            },
         },
         MuiAccordionDetails: {
            styleOverrides: {
               root: {
                  padding: theme.spacing(1),
               },
            },
         },
      },
   });
};

const httpLink = new HttpLink({ uri: ENV.REACT_APP_GRAPHQL_ENDPOINT });

const authLink = new ApolloLink((operation, forward) => {
   const token = localStorage.getItem(ENV.REACT_APP_TOKEN_KEY);

   // Use the setContext method to set the HTTP headers.
   operation.setContext({
      headers: {
         authorization: token ? `Bearer ${token}` : '',
      },
   });

   return forward(operation);
});

export const setupApolloClient = () => {
   return new ApolloClient({
      defaultOptions: {
         watchQuery: {
            fetchPolicy: 'network-only',
         },
         query: {
            fetchPolicy: 'network-only',
         },
      },
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
   });
};

import React, { useMemo } from 'react';

import { Box, Grid } from '@mui/material';

import { PORTAL_MODULES } from 'structure';

import MenuCard from './components/menu-card/menu-card';
import homeStyles from './style';
import { IModel } from './types';

import companyImageSide from '../../assets/graphics/logoWithName.png';
import { useAppSelector } from 'store';
import { findRole } from 'utils/utils';
import useIitMe from 'hooks/use-iit-me';
import { Navigate } from 'react-router-dom';
import { NAVIGATION_PATHS } from 'utils/routes';
import { GetQueryParameter } from 'containers/shell';
import { VIEW_MODES } from 'utils/enums';

export default function Home() {
   const { root, companyImage } = homeStyles();

   const queryViewMode = GetQueryParameter();
   const viewMode = queryViewMode.get('viewMode');
   const IS_WEB_VIEW_MODE = viewMode === VIEW_MODES.WEB_VIEW_MODE;

   useIitMe();
   const { access_token, info: USER } = useAppSelector((state) => state.user);

   const MODULES = useMemo(
      () => PORTAL_MODULES.filter((module) => findRole(module?.roles, USER?.roles)),
      [USER],
   );

   if (!access_token)
      return (
         <Navigate
            to={
               IS_WEB_VIEW_MODE
                  ? `/${NAVIGATION_PATHS.LOGIN}?viewMode=webViewMode`
                  : `/${NAVIGATION_PATHS.LOGIN}`
            }
         />
      );
   return (
      <Box display="flex" className={root}>
         <Box
            className={companyImage}
            component="img"
            src={companyImageSide}
            marginBottom={15}
         />
         <Grid container spacing={2} justifyContent="center">
            {MODULES.map((model: IModel, index: number) => (
               <Grid
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  xl={2}
                  key={index}
               >
                  <MenuCard model={model} />
               </Grid>
            ))}
         </Grid>
      </Box>
   );
}

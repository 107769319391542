import { gql } from '@apollo/client';
import { ListInfoFragment, UserFragment } from 'graphql/fragments';

export const FIND_ALL_USERS = gql`
   ${UserFragment}
   ${ListInfoFragment}
   query findAllUsers($listInput: ListInput!, $groupId: ID) {
      findAllUsers(listInput: $listInput, groupId: $groupId) {
         users {
            ...UserFragment
         }
         listInfo {
            ...ListInfoFragment
         }
      }
   }
`;

import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { IAlertToaster } from './interfaces';

const subject = new Subject();

type data<T = unknown> = T | IAlertToaster;

export const PubSub = {
   publishWithTopic: (topic?: unknown, component?: unknown, data?: data) =>
      subject.next({
         topic,
         component,
         data,
      }),
   getObservableWithTopic: (topic: unknown) =>
      subject.pipe(filter((f: any) => f.topic === topic)),
   publish: (component: unknown, data: unknown) =>
      subject.next({
         component,
         data,
      }),
   getObservable: () => subject.asObservable(),
};

import React, { useCallback } from 'react';
import { ListItemIcon, Icon } from '@mui/material';

import { LIST_ITEM_ICON_SX, LIST_ITEM_ICON_FONT_SIZE } from 'components/sidebar/style';
import { PortalComponentMenuOption } from 'utils/interfaces';

interface IIitListItemIcon {
   option: PortalComponentMenuOption;
   currentPath: string;
}

export default function IitListItemIcon({ option, currentPath }: IIitListItemIcon) {
   const itemIsSelected = useCallback(
      (option: PortalComponentMenuOption) =>
         option?.url === currentPath ||
         option?.subOptions?.map((option) => option?.url).includes(currentPath),
      [currentPath],
   );

   if (option?.customIcon)
      return <ListItemIcon sx={LIST_ITEM_ICON_SX}>{option?.customIcon}</ListItemIcon>;

   if (option?.icon) {
      return (
         <ListItemIcon sx={LIST_ITEM_ICON_SX}>
            <Icon
               sx={LIST_ITEM_ICON_FONT_SIZE}
               color={itemIsSelected(option) ? 'primary' : 'inherit'}
            >
               {option?.icon}
            </Icon>
         </ListItemIcon>
      );
   }

   return <></>;
}

import { FC } from 'react';
import clsx from 'clsx';

import { Box, SxProps, Theme } from '@mui/material';

import { getInitials } from 'utils/utils';

import profileImageStyles from './style';

type TSize = 'small' | 'medium' | 'large';

interface IProfileImageProps {
   name?: string;
   size?: TSize;
   color?: string;
   sx?: SxProps<Theme>;
}

const IitProfileImage: FC<IProfileImageProps> = ({
   name,
   sx,
   size = 'medium',
   color = 'primary',
}) => {
   const profileImageStyle = profileImageStyles() as any;

   return (
      <Box
         className={clsx(
            profileImageStyle.profileImage,
            profileImageStyle[size],
            profileImageStyle[color],
         )}
         sx={sx}
      >
         {getInitials(name || '')}
      </Box>
   );
};

export { IitProfileImage };

import React from 'react';

import { Box, CircularProgress } from '@mui/material';

import iitLoadingStyles from './style';

interface IIitCircularLoading {
   size?: number;
}

const IitCircularLoading = ({ size = 130 }: IIitCircularLoading) => {
   const iitLoadingStyle = iitLoadingStyles();

   return (
      <Box className={iitLoadingStyle.root}>
         <CircularProgress size={size} />
      </Box>
   );
};

export { IitCircularLoading };

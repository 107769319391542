import { IUser } from 'components/users/user.types';
import { IListInfo } from 'utils/interfaces';

export interface VehiclesByOwnerGqlResponse {
   vehicles: IVehicle[];
   listInfo: IListInfo;
}

export interface VehiclesInPendingGqlResponse {
   vehicles: IVehicle[];
   listInfo: IListInfo;
}

export interface FindVehicleGqlResponse {
   findVehicle: IVehicle;
}

export interface IVehicleDetails {
   color: string;
   brand: string;
   registrationDate: string;
   model: string;
}

export interface IVehicle {
   _id: string;
   plate: string;
   owner: IUser;
   status: VEHICLE_STATUS;
   details: IVehicleDetails;
}

export enum VEHICLE_STATUS {
   APPROVED = 'APPROVED',
   DECLINED = 'DECLINED',
   PENDING = 'PENDING',
}

export interface IDatagridSettings {
   page: number;
   count: number;
   term: string;
   rows: IVehicle[];
}

export const INITIAL_SETTING_STATE = {
   page: 1,
   count: 0,
   term: '',
   rows: [],
};

export enum VEHICLE_COLORS {
   WHITE = 'WHITE',
   BLACK = 'BLACK',
   SILVER = 'SILVER',
   GREY = 'GREY',
   BLUE = 'BLUE',
   RED = 'RED',
   GREEN = 'GREEN',
   BROWN = 'BROWN',
   YELLOW = 'YELLOW',
   ORANGE = 'ORANGE',
}

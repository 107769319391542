import { gql } from '@apollo/client';
import { UserFragment } from 'graphql/fragments';

export const UPDATE_USER = gql`
   ${UserFragment}
   mutation updateUser($input: UpdateUserInput, $userId: ID) {
      updateUser(input: $input, userId: $userId) {
         ...UserFragment
      }
   }
`;

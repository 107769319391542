import { IListInfo, PortalComponent } from 'utils/interfaces';

export interface IUser {
   _id: string;
   firstName: string;
   surname: string;
   email: string;
   phoneNumber: number;
   company: string;
   tin: string;
   birthDate: Date;
}

export enum USER_STATUS {
   ACTIVE = 'ACTIVE',
   INACTIVE = 'INACTIVE',
   WAITING_PAYMENT = 'WAITING_PAYMENT',
   ANALYZING = 'ANALYZING',
}

export interface FindAllUsersGqlResponse {
   findAllUsers: {
      users: IUser[];
      listInfo: IListInfo;
   };
}

export enum USER_ROLE {
   ADMIN = 'ADMIN',
   HOTEL_MANAGER = 'HOTEL_MANAGER',
   TEMPORARY_USER = 'TEMPORARY_USER',
   RESIDENT_USER = 'RESIDENT_USER',
}

export interface PropTypes extends PortalComponent {
   headerButton?: {
      cta: () => void;
      text: string;
   };
}

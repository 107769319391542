import { Navigate } from 'react-router-dom';

import NotFound from 'components/404';
import Home from 'components/home';
import Login from 'components/login';
import PrivacyPolicy from 'components/privacy-policy';

export interface IRoute {
   path: string;
   element?: JSX.Element;
}

export enum NAVIGATION_PATHS {
   LOGIN = 'login',
   HOME = 'home',
   OPERATIONS = 'operations',

   PROFILE = 'profile',
   DASHBOARD = 'dashboard',
   GENERIC_LIST = 'genericList',
   NOT_FOUND = '404',

   VEHICLES = 'vehicles',
   MY_VEHICLES = 'my-vehicles',
   PENDING_VEHICLES = 'pending',

   PASSAGES = 'passages',
   PASSAGES_DETAILS = 'passages-details',
   MY_PASSAGES = 'my-passages',

   USERS = 'users',
   USERS_DETAIL = 'user-detail/:id/:groupId?',
   USER_LIST = 'users-list',
   HOTELS = 'hotels',
   HOTEL_DETAILS = 'hotel-details',

   PRIVACY_POLICY = 'privacy-policy',
}

export const ROUTES: IRoute[] = [
   { path: `/${NAVIGATION_PATHS.LOGIN}`, element: <Login /> },
   { path: `/${NAVIGATION_PATHS.HOME}`, element: <Home /> },
   { path: `/${NAVIGATION_PATHS.NOT_FOUND}`, element: <NotFound /> },
   { path: `/${NAVIGATION_PATHS.PRIVACY_POLICY}`, element: <PrivacyPolicy /> },

   { path: `${NAVIGATION_PATHS.VEHICLES}` },
   { path: `${NAVIGATION_PATHS.VEHICLES}/${NAVIGATION_PATHS.PROFILE}` },
   { path: `${NAVIGATION_PATHS.VEHICLES}/${NAVIGATION_PATHS.PENDING_VEHICLES}` },
   { path: `${NAVIGATION_PATHS.VEHICLES}/${NAVIGATION_PATHS.USERS_DETAIL}/:id` },
   { path: `${NAVIGATION_PATHS.VEHICLES}/${NAVIGATION_PATHS.MY_VEHICLES}` },

   { path: `${NAVIGATION_PATHS.VEHICLES}/${NAVIGATION_PATHS.PASSAGES}` },
   { path: `${NAVIGATION_PATHS.VEHICLES}/${NAVIGATION_PATHS.MY_PASSAGES}` },
   { path: `${NAVIGATION_PATHS.VEHICLES}/${NAVIGATION_PATHS.PASSAGES_DETAILS}/:id` },

   { path: `${NAVIGATION_PATHS.USERS}` },
   // { path: `${NAVIGATION_PATHS.USERS}/${NAVIGATION_PATHS.DASHBOARD}` },
   { path: `${NAVIGATION_PATHS.USERS}/${NAVIGATION_PATHS.PROFILE}` },
   { path: `${NAVIGATION_PATHS.USERS}/${NAVIGATION_PATHS.USER_LIST}` },
   { path: `${NAVIGATION_PATHS.USERS}/${NAVIGATION_PATHS.USERS_DETAIL}` },
   { path: `${NAVIGATION_PATHS.USERS}/${NAVIGATION_PATHS.HOTELS}` },
   { path: `${NAVIGATION_PATHS.USERS}/${NAVIGATION_PATHS.HOTEL_DETAILS}/:id` },

   { path: '*', element: <Navigate to={`/${NAVIGATION_PATHS.NOT_FOUND}`} replace /> },
   { path: '/', element: <Navigate to={`/${NAVIGATION_PATHS.LOGIN}`} replace /> },
];

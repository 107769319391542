import axios from 'axios';
import ENV from '../config/environment';

const HTTP_CLIENT = axios.create({
   baseURL: ENV.REACT_APP_AUTH_ENDPOINT,
});

HTTP_CLIENT.interceptors.request.use(function (config) {
   const token = localStorage.getItem(ENV.REACT_APP_TOKEN_KEY);

   config.headers.Authorization = token ? `Bearer ${token}` : '';
   return config;
});

export default HTTP_CLIENT;

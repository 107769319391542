import { gql } from '@apollo/client';

export const ME = gql`
   query me {
      me {
         _id
         isPasswordGenerated
         firstName
         surname
         email
         phoneNumber
         roles
      }
   }
`;

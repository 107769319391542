import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { themeColors } from '../../../../style/common';

const iitLoadingStyles = makeStyles((theme: Theme) => ({
   root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100vw',
      height: '100vh',
      position: 'fixed',
      zIndex: 9999999,
      top: 0,
      left: 0,
      backgroundColor: `${themeColors.grayEvenLighter}99`,
   },
}));

export default iitLoadingStyles;

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const LIST_ITEM_TEXT_FONT_SIZE = '0.875rem';
export const LIST_ITEM_ICON_SX = { minWidth: 0 };
export const LIST_ITEM_ICON_FONT_SIZE = { fontSize: '1.125rem !important' };

const sidebarStyles = makeStyles((theme: Theme) => ({
   collapseButton: {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'start',
   },
}));

export default sidebarStyles;

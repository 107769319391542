import React, { useMemo } from 'react';

import { Box, Grid } from '@mui/material';

import { IModel } from 'components/home/types';
import { setupTheme } from 'core/general-setup';
import { PORTAL_MODULES } from 'structure';

import ModelCard from './components/module-card/module-card';

import HeaderMenu from '../../header-menu';
import { findRole } from 'utils/utils';
import { useAppSelector } from 'store';
import { IProfile } from 'utils/interfaces';

interface IModulesMenu {
   anchorElModules: HTMLElement | null;
   handleCloseModulesMenu: () => void;
}

export default function ModulesMenu({
   anchorElModules,
   handleCloseModulesMenu,
}: IModulesMenu) {
   const theme = setupTheme();
   const USER: IProfile = useAppSelector((state) => state?.user?.info as IProfile);

   const HEADER_MENU_WIDTH = {
      maxWidth: {
         xs: theme.spacing(40),
         sm: theme.spacing(50),
      },
   };

   const GRID_XS: number = useMemo(() => 12 / PORTAL_MODULES.length, []);

   const MODULES = useMemo(
      () => PORTAL_MODULES.filter((module) => findRole(module?.roles, USER?.roles)),
      [USER],
   );

   return (
      <HeaderMenu
         handleCloseMenu={handleCloseModulesMenu}
         anchorEl={anchorElModules}
         sx={{ ...HEADER_MENU_WIDTH, paddingY: 1, mt: 4 }}
      >
         <Box display="flex">
            <Grid container spacing={2}>
               {MODULES.map((model: IModel, index: number) => (
                  <Grid item xs={GRID_XS} key={index}>
                     <ModelCard model={model} />
                  </Grid>
               ))}
            </Grid>
         </Box>
      </HeaderMenu>
   );
}

import React, { ReactNode } from 'react';

import { Box } from '@mui/material';

interface IIitComponentDefaultLayout {
   children: ReactNode;
}

const IitComponentDefaultLayout = ({ children }: IIitComponentDefaultLayout) => {
   return <Box marginY={2.5}>{children}</Box>;
};

export { IitComponentDefaultLayout };

import { Dispatch, Fragment, SetStateAction, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import {
   List,
   ListItemButton,
   ListItemText,
   Box,
   Collapse,
   Badge,
   Typography,
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import IitListItemIcon from 'components/shared-components/iit-menu-option-icon';
import useIitRouting from 'hooks/iit-use-routing';
import { ArrayString, PortalComponentMenuOption } from 'utils/interfaces';

import listItemStyles from './style';

import { LIST_ITEM_TEXT_FONT_SIZE } from '../../style';

interface IListItems {
   currentPath: string;
   drawerOpen: boolean;
   subItemState: ArrayString;
   setSubItemState: Dispatch<SetStateAction<ArrayString>>;
   menuOptions?: PortalComponentMenuOption[];
}

export default function ListItems({
   menuOptions,
   currentPath,
   subItemState,
   drawerOpen,
   setSubItemState,
}: IListItems) {
   const { t } = useTranslation();
   const { navigateTo } = useIitRouting();

   const { item, closedDrawerItemButton } = listItemStyles();

   const handleClickSubItem = useCallback((option: PortalComponentMenuOption) => {
      if (option?.subOptions) {
         return setSubItemState((prev: ArrayString) => ({
            ...prev,
            [option?.name]: !prev[option?.name],
         }));
      }
      navigateTo(option?.url);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const itemIsSelected = (option: PortalComponentMenuOption) =>
      option?.url === currentPath ||
      option?.subOptions?.map((option) => option?.url).includes(currentPath);

   useEffect(() => {
      menuOptions?.forEach((option) => {
         const found = option?.subOptions?.find((option) => option?.url === currentPath);
         if (found) {
            setSubItemState((prev: ArrayString) => ({
               ...prev,
               [option?.name]: true,
            }));
         }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <List sx={{ padding: 0 }}>
         {menuOptions?.map((option: PortalComponentMenuOption, index: number) => (
            <Fragment key={index}>
               {drawerOpen ? (
                  <ListItemButton
                     selected={itemIsSelected(option)}
                     onClick={() => handleClickSubItem(option)}
                     className={item}
                  >
                     <IitListItemIcon option={option} currentPath={currentPath} />

                     <>
                        <Box
                           display="flex"
                           flexDirection="column"
                           flexGrow={1}
                           marginLeft={2}
                        >
                           <ListItemText
                              primary={t(option?.name)}
                              primaryTypographyProps={{
                                 fontSize: LIST_ITEM_TEXT_FONT_SIZE,
                                 fontWeight: itemIsSelected(option) ? 700 : 400,
                              }}
                           />
                           {option?.subTitle && (
                              <Typography variant="caption">
                                 {option?.subTitle}
                              </Typography>
                           )}
                        </Box>
                        <Badge
                           badgeContent={option?.badgeCount}
                           color="primary"
                           sx={{ marginRight: 2 }}
                        />
                        {option?.subOptions?.length && (
                           <Box marginLeft={2}>
                              {subItemState[option?.name] ? (
                                 <ExpandLess />
                              ) : (
                                 <ExpandMore />
                              )}
                           </Box>
                        )}
                     </>
                  </ListItemButton>
               ) : (
                  <ListItemButton
                     selected={itemIsSelected(option)}
                     onClick={() => handleClickSubItem(option)}
                     className={clsx(item, closedDrawerItemButton)}
                  >
                     <IitListItemIcon option={option} currentPath={currentPath} />
                  </ListItemButton>
               )}
               {option?.subOptions?.length &&
                  subItemState &&
                  option.subOptions.map(
                     (subOption: PortalComponentMenuOption, index: number) => (
                        <Collapse
                           in={subItemState[option?.name]}
                           timeout="auto"
                           unmountOnExit
                           key={index}
                        >
                           <List component="div" disablePadding>
                              <ListItemButton
                                 className={item}
                                 selected={subOption?.url === currentPath}
                                 sx={{ paddingX: option?.customIcon ? 2.1 : 1 }}
                                 onClick={() =>
                                    subOption?.handleClick
                                       ? subOption?.handleClick()
                                       : navigateTo(subOption?.url)
                                 }
                              >
                                 <Box marginRight={4.5} />
                                 <Box
                                    display="flex"
                                    width="calc(100% - 3.25rem)"
                                    alignItems="center"
                                 >
                                    <ListItemText
                                       primary={t(subOption?.name)}
                                       primaryTypographyProps={{
                                          fontSize: LIST_ITEM_TEXT_FONT_SIZE,
                                       }}
                                    />
                                    {subOption?.badgeCount && (
                                       <Badge
                                          badgeContent={subOption?.badgeCount}
                                          color="primary"
                                       />
                                    )}
                                 </Box>
                              </ListItemButton>
                           </List>
                        </Collapse>
                     ),
                  )}
            </Fragment>
         ))}
      </List>
   );
}

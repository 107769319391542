import 'devextreme/dist/css/dx.light.css';

import React from 'react';
import { setupI18n } from './core/general-setup';
import Providers from 'components/Providers';
import AppNavigation from 'components/AppNavigation';

setupI18n();
export default function App() {
   return (
      <React.Fragment>
         <Providers>
            <AppNavigation />
         </Providers>
      </React.Fragment>
   );
}

import React, { ReactNode } from 'react';

import { Menu, SxProps } from '@mui/material';

const MENU_PAPER_PROPS = {
   borderRadius: 2,
   p: 0,
   mt: 3,
   paddingX: 2,
};

interface IHeaderMenu {
   anchorEl: HTMLElement | null;
   children?: ReactNode;
   handleCloseMenu: () => void;
   sx?: SxProps;
}

export default function HeaderMenu({
   anchorEl,
   children,
   sx,
   handleCloseMenu,
}: IHeaderMenu) {
   return (
      <Menu
         keepMounted
         anchorEl={anchorEl}
         open={Boolean(anchorEl)}
         onClose={() => handleCloseMenu()}
         PaperProps={{
            sx: {
               ...MENU_PAPER_PROPS,
               ...sx,
            },
         }}
      >
         {children}
      </Menu>
   );
}

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BuildIcon from '@mui/icons-material/Build';

import { findFirstViewOnInit, navigateMobileNavigation } from './utils';
import { useLocation } from 'react-router-dom';
import mobileNavigationStyles from './style';
import useIitRouting from 'hooks/iit-use-routing';

const navigationButtons = [
   {
      icon: <HomeIcon />,
      label: 'COMPONENT_MOBILE_NAVIGATION.EXPLORE',
   },
   {
      icon: <BuildIcon />,
      label: 'COMPONENT_MOBILE_NAVIGATION.OPERATIONS',
   },
   {
      icon: <AccountCircleIcon />,
      label: 'COMPONENT_MOBILE_NAVIGATION.PROFILE',
   },
];

const MobileNavigation = () => {
   const { t } = useTranslation();
   const { pathname } = useLocation();
   const { navigateTo } = useIitRouting();

   const [value, setValue] = useState<number>(findFirstViewOnInit(pathname));

   const { root } = mobileNavigationStyles();

   return (
      <BottomNavigation
         showLabels
         value={value}
         onChange={(_, newValue: number) => {
            setValue(newValue);
            navigateTo(navigateMobileNavigation(newValue));
         }}
         className={root}
      >
         {navigationButtons.map((button, index) => (
            <BottomNavigationAction
               label={t(button.label)}
               icon={button.icon}
               key={index}
            />
         ))}
      </BottomNavigation>
   );
};

export { MobileNavigation };

import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { setupTheme } from 'core/general-setup';
import { PortalComponentMenuOption } from 'utils/interfaces';

import HeaderMenu from '../../header-menu';
import useIitRouting from 'hooks/iit-use-routing';
import IitListItemIcon from 'components/shared-components/iit-menu-option-icon';

interface IModulesOptionsMenu {
   anchorEl: HTMLElement | null;
   handleClose: () => void;
   menuOptions: PortalComponentMenuOption[];
}

export default function ModulesOptionsMenu({
   anchorEl,
   handleClose,
   menuOptions,
}: IModulesOptionsMenu) {
   const theme = setupTheme();
   const { t } = useTranslation();
   const { pathname } = useLocation();
   const { navigateTo } = useIitRouting();

   const CURRENT_PATH = `/${pathname.split('/')[2]}`;

   const HEADER_MENU_WIDTH = {
      maxWidth: {
         xs: theme.spacing(40),
         sm: theme.spacing(50),
      },
   };

   const isOptionSelected = useCallback(
      (optionUrl: string) => CURRENT_PATH === optionUrl,
      [CURRENT_PATH],
   );

   return (
      <HeaderMenu
         handleCloseMenu={handleClose}
         anchorEl={anchorEl}
         sx={{ ...HEADER_MENU_WIDTH, mt: 4 }}
      >
         {menuOptions?.map((option: PortalComponentMenuOption, index: number) => (
            <Box
               display="flex"
               alignItems="center"
               sx={{ cursor: 'pointer' }}
               marginY={2}
               key={index}
               onClick={() => navigateTo(option?.url)}
            >
               <IitListItemIcon option={option} currentPath={CURRENT_PATH} />
               <Typography
                  variant="body1"
                  color={isOptionSelected(option?.url) ? 'primary' : 'inherit'}
                  fontWeight={isOptionSelected(option?.url) ? 'bold' : ''}
                  marginLeft={2}
               >
                  {t(option?.name)}
               </Typography>
            </Box>
         ))}
      </HeaderMenu>
   );
}

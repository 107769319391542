import React, { FC, ReactNode } from 'react';
import { ApolloProvider } from '@apollo/client';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { setupApolloClient, setupTheme } from 'core/general-setup';

interface PropTypes {
   children: ReactNode;
}

const Providers: FC<PropTypes> = ({ children }) => {
   const apolloClient = setupApolloClient();
   const theme = setupTheme();

   return (
      <ApolloProvider client={apolloClient}>
         <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
         </StyledEngineProvider>
      </ApolloProvider>
   );
};

export default Providers;

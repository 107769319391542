import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
   Box,
   Collapse,
   Divider,
   List,
   ListItemButton,
   ListItemText,
   MenuItem,
   Typography,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';

import { IitProfileImage } from 'components/shared-components/iit-profile-image/iit-profile-image';
import { LANG } from 'core/general-setup';
import useIitRouting from 'hooks/iit-use-routing';
import { useAppDispatch } from 'store';
import { logout } from 'store/reducers/user.reducer';
import { findRole, sendEventToRnWebview } from 'utils/utils';
import { RN_WEBVIEW_EVENTS, ROLES_ACCESS } from 'utils/constants';
import { IProfile } from 'utils/interfaces';
import { AvailableLanguages, LANGUAGE_TRANSLATION } from 'utils/enums';
import { NAVIGATION_PATHS } from 'utils/routes';

import headerUserMenuStyles from './style';
import { defaultLang, ILanguage } from './utils';

import HeaderMenu from '../../header-menu';

interface IHeaderUserMenu {
   currentUser?: IProfile;
   anchorElUser: HTMLElement | null;
   handleCloseUserMenu: () => void;
}

export const LANGUAGES: ILanguage[] = [
   { name: LANGUAGE_TRANSLATION.PT, value: AvailableLanguages.PT },
   { name: LANGUAGE_TRANSLATION.EN, value: AvailableLanguages.EN },
];

export default function HeaderUserMenu({
   currentUser,
   anchorElUser,
   handleCloseUserMenu,
}: IHeaderUserMenu) {
   const STORED_LANG = localStorage.getItem(LANG);

   const AVAILABLE_LANGUAGES = useMemo(
      (): ILanguage[] =>
         LANGUAGES.map((language: ILanguage) => ({
            ...language,
            current: language.value === STORED_LANG,
         })),
      [STORED_LANG],
   );

   const DEFAULT_LANG = useMemo(() => defaultLang(STORED_LANG), [STORED_LANG]);

   const [openLanguages, setOpenLanguages] = useState(false);
   const [language, setLanguage] = useState<ILanguage>(DEFAULT_LANG);
   const [availableLanguages, setAvailableLanguages] =
      useState<ILanguage[]>(AVAILABLE_LANGUAGES);

   const { divider } = headerUserMenuStyles();

   const { t, i18n } = useTranslation();
   const { navigateTo } = useIitRouting();
   const dispatch = useAppDispatch();

   const MENU_LIST = useMemo(
      () => [
         {
            text: 'HEADER.EDIT_PROFILE',
            handleClick: () => navigateTo(`/${NAVIGATION_PATHS.PROFILE}`),
         },
      ],
      [navigateTo],
   );

   const changeLanguage = useCallback(
      (language: ILanguage) => {
         setLanguage(language);
         const langs = availableLanguages.map((lang) => ({
            ...lang,
            current: lang.value === language.value,
         }));
         sendEventToRnWebview({
            value: language?.value,
            type: RN_WEBVIEW_EVENTS.CHANGE_LANGUAGE,
         });
         setAvailableLanguages(langs);
         localStorage.setItem(LANG, language.value);
         i18n.changeLanguage(language.value);
      },
      [availableLanguages, i18n],
   );

   const handleLogout = useCallback(() => {
      dispatch(logout());
      sendEventToRnWebview({
         type: RN_WEBVIEW_EVENTS.LOGOUT,
      });
   }, [dispatch]);

   return (
      <HeaderMenu
         handleCloseMenu={handleCloseUserMenu}
         anchorEl={anchorElUser}
         sx={{ paddingX: 0 }}
      >
         <Box
            display="flex"
            alignItems="center"
            paddingY={1}
            paddingX={2}
            marginBottom={2}
         >
            <IitProfileImage
               name={currentUser?.fullName}
               color="secondary"
               sx={{ mr: 2 }}
            />
            <Box>
               <Typography variant="body1">{currentUser?.fullName}</Typography>
               <Typography variant="body2">{currentUser?.email}</Typography>
               {findRole(ROLES_ACCESS.ADMIN, currentUser?.roles) && (
                  <Typography variant="body2" color="grey">
                     {t('GENERAL.ADMIN')}
                  </Typography>
               )}
            </Box>
         </Box>

         {MENU_LIST.map(({ text, handleClick }, index: number) => (
            <MenuItem key={index} onClick={handleClick}>
               <Typography textAlign="center">{t(text)}</Typography>
            </MenuItem>
         ))}

         <Divider className={divider} />
         <ListItemButton onClick={() => setOpenLanguages(!openLanguages)}>
            <ListItemText primary={t(language?.name as string)} />
            {openLanguages ? <ExpandLess /> : <ExpandMore />}
         </ListItemButton>
         <Collapse in={openLanguages} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
               {availableLanguages.map((language: ILanguage, index: number) => (
                  <ListItemButton
                     sx={{ pl: 4 }}
                     key={index}
                     onClick={() => changeLanguage(language)}
                  >
                     <ListItemText primary={t(language?.name)} sx={{ flexGrow: 1 }} />
                     {language?.current && <CheckIcon />}
                  </ListItemButton>
               ))}
            </List>
         </Collapse>
         <Divider className={divider} />
         <MenuItem onClick={handleLogout}>
            <Typography textAlign="center">{t('GENERAL.LOGOUT')}</Typography>
         </MenuItem>
      </HeaderMenu>
   );
}

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const homeStyles = makeStyles((theme: Theme) => ({
   root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      [theme.breakpoints.up('xs')]: {
         padding: `${theme.spacing(8)} ${theme.spacing(4)} ${theme.spacing(8)}`,
      },
      [theme.breakpoints.up('sm')]: {
         height: '100svh',
         padding: `${theme.spacing(0)} ${theme.spacing(10)} ${theme.spacing(0)}`,
      },
      [theme.breakpoints.up('lg')]: {
         paddingLeft: theme.spacing(20),
         paddingRight: theme.spacing(20),
      },
   },
   companyImage: {
      width: theme.spacing(32.375),
   },
}));

export default homeStyles;

import { useEffect, useCallback } from 'react';

import { PubSub } from 'utils/pubsub';
import { Nullable } from 'utils/types';

interface IUseIitGetObservableWithTopic<T> {
   onCompleted?: (data: Nullable<T>) => void;
}

export const useIitGetObservableWithTopic = <T>(
   topic: string,
   { onCompleted }: IUseIitGetObservableWithTopic<T> = {},
) => {
   const getObservableWithTopic = useCallback(() => {
      const subRefresh = PubSub.getObservableWithTopic(topic).subscribe(async (value) => {
         onCompleted && onCompleted(value.data);
      });
      return () => {
         subRefresh.unsubscribe();
      };
   }, [onCompleted, topic]);

   // eslint-disable-next-line react-hooks/exhaustive-deps
   useEffect(() => getObservableWithTopic(), []);
};

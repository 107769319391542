import { NAVIGATION_PATHS } from 'utils/routes';

export const showComponentMobile = (
   path: string,
   isWebViewMode: boolean,
   isMobile: boolean,
) => {
   const PATH_MODIFIED = path.replace('/', '');

   const OPERATIONS_PAGE = PATH_MODIFIED === NAVIGATION_PATHS.OPERATIONS;
   const PROFILE_PAGE = PATH_MODIFIED === NAVIGATION_PATHS.PROFILE;

   if (isWebViewMode && isMobile) return true;
   if (!isWebViewMode && !isMobile) return true;

   return (isWebViewMode || isMobile) && (OPERATIONS_PAGE || PROFILE_PAGE);
};
